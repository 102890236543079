import React from "react";

const Cards = ({ doctor }) => {
  return (
    <div className="bg-slate-200 md:w-50 lg:w-80 sm:w-80 shadow-lg rounded-xl px-8 py-2 h-full">
      <div className="text-center">
        <a href="#" className="relative block">
          <img
            alt="profile"
            src={doctor.photo}
            className="mx-auto object-cover rounded-full h-24 w-24"
          />
        </a>
      </div>
      <p className="text-gray-600 text-white text-center ">
        <span className="md:text-2xl font-bold text-indigo-500">
          {doctor.name?.substring(0, 20)}
        </span>
      </p>
      <p className="text-gray-600 text-white text-center">
        <span className="text-lg font-bold text-indigo-500"></span>
        {doctor.description}
        <span className="md:text-lg font-bold text-indigo-500"></span>
      </p>
    </div>
  );
};

export default Cards;
