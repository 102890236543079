import React from "react";
import chairImage from "../../../assets/images/chair.png";
import "./Banner.css";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import ThreeDImage from "../../../ThreeDImage";

const Banner = () => {
  return (
    <div>
      <div className="hero sm:h-[80vh] mb-16 mt-0 ">
        <ThreeDImage></ThreeDImage>
        {/* <img src={bgImage} className="bg-center w-full bg-cover" /> */}

        <div className="hero-content flex-col lg:flex-row-reverse text-center">
          <Fade left>
            <div>
              <h1 className="text-4xl font-semibold text-center">
                <span className="text-green-700 text-5xl">Book</span> Doctor
                Appointments Online{" "}
                <span className="text-green-700 text-5xl">with DaktarBD</span>
              </h1>

              <p className="py-6 font-googFont2 text-xl">
                Book your doctor appointments easily and securely in just 30
                seconds. Available 24/7 without waiting. Receive automatic
                appointment reminders via SMS.
              </p>
              <button className="btn bg-gradient border-none font-googFont3 text-2xl mb-24 ">
                <Link to="/appointment">Book Appointment</Link>
              </button>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default Banner;
