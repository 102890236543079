import React from "react";
import { Link } from "react-router-dom";
import PrimaryButtons from "../../assets/components/PrimaryButtons";
import treatment from "../../assets/images/treatment.png";
import "../Testimonial/Testimonial.css";

const DentalCare = () => {
  return (
    <div>
      <div className="hero my-16 testimonial-section">
        <div className="hero-content    flex-col lg:flex-row  ">
          <div className="w-1/2  flex lg:justify-end justify-center">
            <img
              src={treatment}
              className="lg:mr-8 rounded-lg shadow-2xl w-1/2"
            />
          </div>
          <div className="w-1/2 ml-8">
            <h1 className="text-3xl font-bold">
              Exceptional Dental Care, on Your Terms
            </h1>
            <p className="py-6 font-googleFont8 text-xl   text-slate-600   ">
              Discover exceptional dental care that's designed with you in mind.
              Our dedicated team is committed to providing personalized services
              that cater to your needs and preferences. From routine check-ups
              to advanced treatments, we're here to ensure your dental
              experience is comfortable and effective. Experience dental care on
              your terms and enjoy a healthier, happier smile.
            </p>
            <button className="btn bg-gradient border-none font-googFont3 text-2xl mb-24 ">
              <Link to="/appointment">Book Appointment</Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DentalCare;
