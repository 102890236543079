import React from "react";

import doctorImage from "../../assets/images/doctor.png";
import ThreeDImage from "../../ThreeDImage";

const About = () => {
  return (
    <div
      className="hero min-h-screen"
      style={{
        backgroundImage: `url(${doctorImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <ThreeDImage></ThreeDImage>
      <div className="hero-overlay bg-opacity-60"></div>
      <div className="hero-content  text-neutral-content">
        <div className="border-2 px-3 text-justify font-googleFont5">
          <h1 className="mb-5 text-5xl font-bold ">Who we are ?</h1>
          <p className="mb-5 font-googFont2 font-bold">
            DAKTARBD is a well-known name in Dental and Oral Care in Bangladesh.
            The journey of this institution started in 1977 under the hands of
            Dr. Kazi Mehdi Ul Alam, Gold Medalist of Dhaka University. Dhanmondi
            Dental Center has been leading the way in Dental Treatment in
            Bangladesh for more than 40 years in keeping with the evolution of
            time and the modernization of the era. With the passage of time,
            dentistry has expanded and there have been availability of
            specialist doctors in various departments. Currently in each
            department of this center like Dental Implants, Orthodontic
            Treatment, Cosmetic Dentistry, Oral & Maxillofacial Surgery, Root
            Canal, Crown, Bridges, Prosthesis, Filing, Periodontology,
            specialist doctors with degrees from home and abroad and highly
            trained in abroad provide treatment to patients in this center. We
            have long experience of serving domestic and foreign patients. We
            have served more than 50,000 patients. <br></br> We have a
            successful track record of over 800 dental implant surgeries,
            thousands of root canals and dental prostheses. With the work
            experience in this center, many famous dental surgeons of the
            country are contributing to the Dentistry field with reputedly. Now
            a days various technologies is added with experience and expertise.
            The way of modernization of this center has introduced latest
            technology and international standard dental products which is used
            in the developed countries. With the mentality of serving people,
            this center is still a place of excellence for dental treatment for
            patients from home and abroad and a trustworthy institution for
            dentistry
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
