import React from "react";
import fluoride from "../../assets/images/fluoride.png";
import cavity from "../../assets/images/cavity.png";
import whitening from "../../assets/images/whitening.png";
import Service from "./Service";

const Services = () => {
  const servicesData = [
    {
      id: 1,
      name: "Teeth Cleaning (Dental Prophylaxis)",
      description:
        "Our teeth cleaning service, also known as dental prophylaxis, involves removing plaque, tartar, and stains from your teeth's surfaces. This preventive procedure helps maintain your oral health and prevent gum disease.",
      img: fluoride,
    },
    {
      id: 2,
      name: "Teeth Whitening (Bleaching)",
      description:
        "Achieve a brighter and whiter smile with our teeth whitening treatment, also known as bleaching. This procedure effectively lightens the shade of your teeth, removing discolorations and stains caused by factors like aging, food, and beverages.",
      img: cavity,
    },
    {
      id: 3,
      name: "Dental Fillings",
      description:
        "Our dental filling service is designed to restore teeth that have been damaged by decay or minor fractures. We use durable and tooth-colored materials to fill the cavities, providing both functionality and aesthetic appeal.",
      img: whitening,
    },
  ];
  return (
    <div className="mt-16 font-googFont2  text-xl">
      <div className="text-center">
        <h3 className="text-3xl  font-bold text-green-400 uppercase">
          Our Services
        </h3>
        <h2 className="text-3xl">Empowering Your Well-being</h2>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-8">
        {servicesData.map((serve) => (
          <Service key={serve.id} serve={serve}></Service>
        ))}
      </div>
    </div>
  );
};

export default Services;
