import React from "react";

const Contact = () => {
  return (
    <div className="w-full p-6 bg-white rounded-lg shadow-md border border-gray-200 ">
      <div className="bg-blue-500 text-white p-4 rounded-t-lg text-center mb-6 ">
        <p className="font-semibold">
          Reach us at{" "}
          <a href="mailto:contact@daktarbd.com" className="underline">
            contact@daktarbd.com
          </a>
        </p>
      </div>
      <h2 className="text-3xl font-semibold mb-6">Contact Us</h2>
      <form>
        <div className="mb-4">
          <label htmlFor="name" className="block font-medium mb-1">
            Your Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            className="form-input w-full px-4 py-2 border border-gray-300 focus:outline-none focus:border-blue-500"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="email" className="block font-medium mb-1">
            Your Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            className="form-input w-full px-4 py-2 border border-gray-300 focus:outline-none focus:border-blue-500"
            required
          />
        </div>
        <div className="mb-6">
          <label htmlFor="message" className="block font-medium mb-1">
            Your Message
          </label>
          <textarea
            id="message"
            name="message"
            rows="4"
            className="form-input w-full px-4 py-2 border border-gray-300 focus:outline-none focus:border-blue-500"
            required
          ></textarea>
        </div>
        <button
          type="submit"
          className="btn btn-primary w-full py-2 px-4 text-white rounded-lg bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default Contact;
