import React from "react";

const Service = ({ serve }) => {
  const { name, description, img } = serve;
  return (
    <div className="card  bg-base-100 shadow-xl">
      <figure className="px-10 pt-10">
        <img src={img} alt="Shoes" className="rounded-xl" />
      </figure>
      <div className="card-body items-center text-center">
        <h2 className="card-title">{name}</h2>
        <p className="font-googleFont11    ">{description}</p>
      </div>
    </div>
  );
};

export default Service;
