import React, { useEffect } from "react";

import "@splidejs/splide/dist/css/themes/splide-default.min.css";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import Cards from "./Cards";

const Testimonial = () => {
  const placeholderData = [
    {
      id: 1,
      name: "Dr. Md. Masudur Rahman Iqbal",
      specialization: "Oral & Maxillofacial Surgery",
      description:
        "BDS (DDC), BCS (Health) MS (Oral & Maxillofacial Surgery) Associate Professor Dhaka Dental College BM&DC No-789",
      photo:
        "https://ddentalcenter.com/img/doctors/40eb6fb6da4c72849d01dbd712349f01.jpg",
    },
    {
      id: 2,
      name: "Dr. Md Abdul Awal ",
      specialization: "Prosthodontics & Cosmetic Dentistry",
      description:
        "BDS (DDC), BCS (Health) MS (Prosthodontics) Asst. Professor Dhaka Dental College BM&DC No-765advanced orthodontic treatments.",
      photo:
        "https://ddentalcenter.com/img/doctors/b289954cefb67ffd9b8af2a6572e41f5.jpg",
    },
    {
      id: 3,
      name: "Dr. Israt Mafruha ",
      specialization: "Orthodontic",
      description:
        "BDS (RAJ), MS (Orthodontic) Associate Professor Dhaka Community Medical College (Dental Unit) BM&DC No-2359 ",
      photo:
        "https://ddentalcenter.com/img/doctors/5b2a739ba35d0a1641c913f2b19d4230.jpg",
    },
    {
      id: 4,
      name: "Dr. Monika Chakraborty  ",
      specialization: "Assistant Professor Dhaka Community Medical College",
      description: "(Dental Unit) BDS (CMC-DU), DDS (BSMMU) BM&DC - 5072 ",
      photo:
        "https://ddentalcenter.com/img/doctors/26f24657d95061c33c746b7e490e4cfb.jpg",
    },
  ];
  return (
    <Splide
      className="my-24"
      options={{
        type: "loop",
        gap: "1em",
        drag: "free",
        speed: 2,
        arrows: true,
        pagination: true,
        perPage: 3,

        breakpoints: {
          100: {
            perPage: 1,
            gap: "2em",
          },
          300: {
            perPage: 1,
            gap: "2em",
          },
          635: { perPage: 1, gap: "2em" },
        },

        autoScroll: {
          pauseOnHover: true,
          pauseOnFocus: false,
          rewind: false,
          speed: 1,
        },
      }}
      extensions={{ AutoScroll }}
    >
      {placeholderData.map((doctor) => (
        <SplideSlide key={doctor.id}>
          <Cards doctor={doctor} />
        </SplideSlide>
      ))}
    </Splide>
  );
};

export default Testimonial;
